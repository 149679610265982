//
// Compiled Site Scripts
// ----------------------------------------------------------------------------
// Warning: Global.js is a COMPILED file! Edit a source file, or create a new
// one. See gulpfile.js!
//
//  #######
//     #    #    # #  ####      #  ####        ##
//     #    #    # # #          # #           #  #
//     #    ###### #  ####      #  ####      #    #
//     #    #    # #      #     #      #     ######
//     #    #    # # #    #     # #    #     #    #
//     #    #    # #  ####      #  ####      #    #

//  #####  ####### #     # ######  ### #       ####### ######
// #     # #     # ##   ## #     #  #  #       #       #     #
// #       #     # # # # # #     #  #  #       #       #     #
// #       #     # #  #  # ######   #  #       #####   #     #
// #       #     # #     # #        #  #       #       #     #
// #     # #     # #     # #        #  #       #       #     #
//  #####  ####### #     # #       ### ####### ####### ######
//
// ####### ### #       #######
// #        #  #       #
// #        #  #       #
// #####    #  #       #####
// #        #  #       #
// #        #  #       #
// #       ### ####### #######
//
// Warning: Global.js is a COMPILED file! Edit a source file, or create a new
// one. See gulpfile.js!
// ----------------------------------------------------------------------------



