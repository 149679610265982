//
// Modal Search System
// --------------------------------------------------
// Variables defined in perch-core-scripts.js
// --------------------------------------------------


// Variables
// ---------------------------------------
var $search_reveal_button	= $('.global-search-reveal-button'),
	$search_menu 			= $('#global-search-overlay'),
	$search_input 			= $('#global-search-overlay__input'),
	$search_submit 			= $('#global-search-overlay__submit'),
	$search_close_btn 		= $('#global-search-overlay__close-button');
// ---------------------------------------


// Functions
// ---------------------------------------

// Search opening
// --------------------------
function openSearch () {
	// First, make the link highlighted & show the menu
	$search_reveal_button.addClass('is-active').attr('aria-expanded', 'true').attr('aria-pressed', 'true');
	$search_menu.addClass('is-active');
	// Then, select the input field
	$search_input.select();
}


// Search closing
// --------------------------
function closeSearch () {
	// Remove classes to the menu and the link
	// $(this).offsetWidth = $(this).offsetWidth;
	$search_menu.addClass('animating-out');
	setTimeout(function() {
		$search_reveal_button.removeClass('is-active').attr('aria-expanded', 'false').attr('aria-pressed', 'false');
		$search_menu.removeClass('is-active');
		$search_menu.removeClass('animating-out');
	}, 250);
	return false;
};


// Logic
// ---------------------------------------

// Close the menu if it's currently active. Otherwise: run openSearch function.
$search_reveal_button.click(function() {
	if ($(this).hasClass('is-active')) {
		closeSearch();
	}

	else {
		openSearch();
	}
});


// Open the search menu if any anchor with a certain class is clicked
$search_trigger.click(function() {
	// Add classes to the menu and the link
	$search_reveal_button.addClass('is-active');
	$search_menu.addClass('is-active');

	// Focus the search input
	$(this).blur();
	$search_input.select();

	return false;
});


// Clicking anywhere in the search's background overlay — except within the actual form — closes the overlay
$search_menu.click(closeSearch);
	// ...Do not close if the click is inside the inner container.
	$('.global-search-overlay__inner').click(function(event) {
		event.stopPropagation();
	});
$search_close_btn.click(closeSearch);


// Search keyboard assist: when search menu is open, keep focus within the search menu
// by focusing "back to" the corner closer button, which allows the user to close if desired,
// or continue to the next focusable field, the input itself
$search_close_btn.on('focusout', function() {
	$search_input.focus();
});
