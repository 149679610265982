//
// Perch Navigation / Menu System
// --------------------------------------------------
// Variables defined in perch-core-scripts.js
// --------------------------------------------------


// Functions
// ---------------------------------------


// Mobile nav opening
// --------------------------
function openMobileNav () {
	$('html').addClass('mobile-nav-is-open is-mobile-scroll-locked');
	$nav_reveal_button.addClass('is-active').attr('aria-expanded', 'true').attr('aria-pressed', 'true');
	$nav_outer_wrapper.addClass('is-active');
	$global_header.addClass('mobile-nav-is-open');
}


// Navigation opening
// --------------------------
// Opens the menu for both mobile and desktop.
// Also can accept an argument to open a specific child menu.
// 	> Could be used to open the navigation by clicking a link or button on the page.
// 	> Was once used on WMU/WordlCrafts sidebar
// --------------------------
function openNav (the_menu) {

	// Use interpolation to take the passed child menu name and then select it below
	var interpolated_selector = ".menu-" + the_menu;
	$(interpolated_selector)
		.focus()
		.addClass('is-open submenu-is-open');
	$(interpolated_selector).children($nav_child).addClass('is-open');
	$(interpolated_selector).children('.child-nav-opener').addClass('is-active');

	// Feedback to the body: a child menu is open. Allows for making a backdrop show up over the content/beneath the menu.
	// $('body').addClass('child-menu-is-open');

	openMobileNav();
}


// Navigation closing
// --------------------------
function closeNav () {
	$('html').removeClass('mobile-nav-is-open is-mobile-scroll-locked');
	$nav_outer_wrapper.addClass('animating-out');
	$nav_reveal_button.removeClass('is-active').attr('aria-expanded', 'false').attr('aria-pressed', 'false');
	$global_header.removeClass('mobile-nav-is-open');

	// Close megamenus for mobile...before animation is finished
	$('.global-nav-item').removeClass('is-open submenu-is-open submenu-is-mobile-open');
	$('body').removeClass('child-menu-is-open');

	var nav_close_timer = setTimeout(function() {
		// Close megamenus for mobile...after animation is finished
		$('.child-nav-opener').removeClass('is-active');
		$nav_child.removeClass('is-open');

		$nav_outer_wrapper.removeClass('is-active');
		$nav_outer_wrapper.removeClass('animating-out');
	}, 250);

	// also close the search
	if (typeof closeSearch == 'function') {
		closeSearch();
	}

	return false;
}


// Logic
// ---------------------------------------

// Navigation
// --------------------------

// Close the menu if it's currently active. Otherwise: run openNav function.
$nav_reveal_button.on('click keydown', function(e) {
	if ((e.type == 'click') || (e.keyCode == 32)) {
		e.preventDefault();

		if ($(this).hasClass('is-active')) {
			closeNav();
			this.blur(); // Remove the focus from the button so :focus styles don't show on touch screens

		} else {
			openNav();
		}
	}
});


// 💀💀💀💀 REMOVED 💀💀💀💀
// Removed because:
//  - breaks the $nav_link_opens_child functionality
// Keeping commented code because:
//  - contains notes for how it could be re-written for Perch as a Library file

	// Notify body hovered nav is open
	// --------------------------
	// Note: we need to be sure that this only selects top parents, otherwise, if all parents in the tree are selected, the mouseleave will be fired when mouse moves away from them.
	// $main_nav_parent
	// 	.mouseenter(function (e) {
	// 		$('body').addClass('child-menu-is-open');
	// 	})
	// 	.mouseleave(function (e) {
	// 		$('body').removeClass('child-menu-is-open');
	// 		$(this).removeClass('is-open submenu-is-open submenu-is-mobile-open');
	// 	});
	//
	//
	// Instead of this, consider making something like Amazon's #nav-cover. For them, it is a child element of the nav, but brought in by JS when needed. It has some base CSS styles but is hidden by default. When brought in, inline styles are set, including opacity, display, z-index, and, interestingly, height.
		// I'm wondering if we could do this with an element _not_ brought in by JS, a child of the <header>, that relies on other feedback systems — e.g. the `.mobile-nav-is-open` class — to open. Also, it might work well being set to position: fixed...?

// end removed code 💀💀💀💀
