//
// Dispatcher.js
// v0.3.1
// ----------------------------------------------------------------------------
// Create actions & reactions in markup
// ----------------------------------------------------------------------------
// TODO
// - Allow data-dispatcher-notify to accept a list of classes to notify
// - Allow customization of notification class names (for multiple uses of dispatcher)
// - Rewrite in Vanilla JS
// - Add a callback when dispatch is completed?
// ----------------------------------------------------------------------------


// Functions
// --------------------------------------------------
function clickDispatch (trigger) {
	// Feedback to $(this) element
	// --------------------------
	trigger.toggleClass('is-active');


	// Notified element dispatch
	// --------------------------

	// If an element is to be notified (a value is given in the attribute),
	// notify it/them with toggled class "is-dispatching"
	if ( trigger.attr('data-dispatcher-notify') ) {
		var $notify_class = "." + trigger.data('dispatcher-notify');
		$($notify_class).toggleClass("is-dispatcher-notified");
	}


	// Target element dispatch
	// --------------------------

	// Get the targeted element via data-attribute
	var $target = "#" + trigger.data("dispatcher-target");

	// if an attribute data-dispatcher-target-active-class __exists and has a value__
	if ( trigger.attr('data-dispatcher-target-active-class') ) {
		var $custom_class = trigger.data('dispatcher-target-active-class');
		$($target).toggleClass($custom_class);
	}
	else {
		$($target).toggleClass("is-active");
	}


	// "One at a time, siblings" dispatch
	// --------------------------
	// For when only one child may be active at a time. (Like an accordion!)
	// --------------------------
	if ( trigger.attr('data-dispatcher-parent') ) {
		// Get the targeted element via data-attribute
		var $parent = "#" + trigger.data("dispatcher-parent");

		// These may _seem_ reversed, but note that at the top of this function, the is-active class is toggled. So at this point, we're not checking "if the trigger had is-active before click", but "if the trigger has is-active now that it has been clicked".
		if ( trigger.hasClass("is-active") ) {
			$($parent).addClass("has-dispatched-active-child");
		}
		else {
			$($parent).removeClass("has-dispatched-active-child");
		}

		trigger.siblings('.dispatcher-trigger').removeClass("is-active");
	}
}


// Logic
// --------------------------------------------------
$(document).ready(function() {
	// keypress event added here because some elements send a "click" event when return key is pressed (e.g. <button>), but some don't (e.g. <li> if tabindex="0" is added, allowing keyboard selection)
	$('body').on("click keypress", '.dispatcher-trigger', function(event) {
		// first, listen for the more common/more likely click event...
		if ( event.type === 'click') {
			// Prevent default click actions
			event.preventDefault();
			// Fire the main function
			clickDispatch( $(this) );
		}
		// otherwise, covering the minority cases, listen for the return key
		else {
			if ( event.which == 13 ) {
				// Prevent default click actions
				event.preventDefault();
				// Fire the main function
				clickDispatch( $(this) );
			}
		}
	});
});
